<template>
    <layout>
        <div v-if="emailSent" class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card bg-pattern">
                    <div class="card-body p-4">
                        <div class="text-center w-75 m-auto">
                            <router-link to="/">
                                <span>
                                    <img src="@assets/images/logo_hover.svg" alt height="50" />
                                </span>
                            </router-link>
                        </div>
                        <div class="mt-3 text-center">
                            <p class="text-muted mt-2">
                                An email has been sent. Please check for an email from
                                company and click on the included link to reset your password.
                            </p>
                            <router-link
                                tag="a"
                                to="/login"
                                class="btn btn-block btn-primary waves-effect waves-light mt-3"
                            >
                                Back to Home
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card bg-pattern">
                    <div class="card-body p-4">
                        <div class="text-center w-75 m-auto">
                            <router-link to="/">
                                <span>
                                    <img src="@assets/images/logo_hover.svg" alt height="50" />
                                </span>
                            </router-link>
                            <p class="text-muted mb-4 mt-3">
                                Enter your email address and we'll send you an email with instructions to reset your password.
                            </p>
                        </div>
                        <form @submit.stop.prevent="tryToSendLink">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input
                                    id="email"
                                    v-model="email"
                                    :class="{ 'is-invalid': $v.email.$error }"
                                    type="email"
                                    class="form-control"
                                    placeholder="Enter Email"
                                    :error-messages="emailErrors"
                                    @input="$v.email.$touch;"
                                    @blur="$v.email.$touch"
                                />
                                <div
                                    v-if="emailErrors.length > 0"
                                    class="invalid-feedback"
                                >
                                    {{ emailErrors[0] }}
                                </div>
                            </div>
                            <button
                                type="submit"
                                class="btn btn-success btn-block waves-effect waves-light"
                            >
                                Send Reset Password Link
                            </button>
                        </form>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 text-center">
                        <p class="text-white-50">
                            Already have account?
                            <router-link tag="a" to="/login" class="text-white ml-1">
                                <b>Log In</b>
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@layouts/auth';
import { authMethods } from '@state/helpers';
import appConfig from '@src/app.config';
import { required, email } from 'vuelidate/lib/validators';

export default {
    page: {
        title: 'Forget Password',
        meta: [
            {
                name: 'description',
                content: `Forget Password to ${appConfig.title}`,
            },
        ],
    },
    components: { Layout },
    data() {
        return {
            email: '',
            emailSent: false,
        };
    },

    validations: {
        email: { required, email },
    },

    computed: {
        emailErrors() {
            const errors = [];

            if (!this.$v.email.$dirty) return errors;

            !this.$v.email.required && errors.push('Email is required.');

            return errors;
        },
    },
    methods: {
        ...authMethods,

        async tryToSendLink() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.sendResetPasswordLink({
                    email: this.email,
                });
                this.email = '';
                this.emailSent = true;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },
    },
};
</script>


